<!-- On transmet ici non pas la reservation mais un SalesOrder -->
<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!this.salesOrder || this.loading">
      <span class="loader"></span>
    </span>
    <div v-if="this.salesOrder">
      <el-collapse accordion value="0">
        <!-- region facturation et reservation -->
        <el-collapse-item name="1">
          <template slot="title">
            <h3>
              {{ $t('BOOKINGS.BOOKING_INFORMATION') }}
            </h3>
          </template>
          <!-- Les informations de la réservation -->
          <div v-if="!this.selectedBooking">
            <booking-card
                v-for="(item, i) in this.salesOrder.items"
                :key="i"
                :orderItem="item"
                :booking="item.salesOrderable"
                @onEditBooking="editBooking"/>
          </div>
          <!-- Le formulaire de modification -->
          <edit-booking-form
              v-if="this.selectedBooking"
              @onBookingEditionDone="onBookingEditionDone"
              @onBookingEditionCancelled="onBookingEditionCancelled"
              :booking-data="this.selectedBooking">
          </edit-booking-form>
          <hr/>
          <!-- La facture ici -->
          <div v-if="!this.selectedBooking" class="container">
            <h3>{{ $t("APPS.INVOICING_MODULE") }}</h3>
            <table class="table" v-for="(item, index) in this.salesOrder.items" :key="index">
              <tr>
                <td>
                  <strong>{{ $t("BOOKINGS.TITLE") }} {{ item.salesOrderable.code }}</strong>
                </td>
                <td>{{ item.pricing.subtotal }} CAD</td>
              </tr>
            </table>
            <!-- Sous total -->
            <table class="table">
              <tr>
                <td>
                  <strong>
                    {{ $t("COMMON.SUBTOTAL") }}
                  </strong>
                </td>
                <td>
                  {{ this.salesOrder.pricing.subtotal }} CAD
                </td>
              </tr>
            </table>
            <!-- Autre et taxes -->
            <table class="table" style="margin-top: 10px">
              <tr>
                <td>TPS</td>
                <td>5.08 CAD</td>
              </tr>
              <tr>
                <td>TVQ</td>
                <td>12,14 CAD</td>
              </tr>
              <tr>
                <td>Autres</td>
                <td>80 CAD</td>
              </tr>
            </table>
            <table class="table">
              <tr>
                <td>
                  <strong>
                    {{ $t("COMMON.TOTAL")}}
                  </strong>
                </td>
                <td>
                  {{ this.salesOrder.pricing.total }} CAD
                </td>
              </tr>
            </table>
          </div>
        </el-collapse-item>
        <!-- endregion -->

        <!-- region client -->
        <el-collapse-item name="2">
          <template slot="title">
            <h3>
              {{ $t('CUSTOMERS.VIEW_CUSTOMER')}}
            </h3>
          </template>
          <div v-if="this.customer && !customerStep" class="row">
            <div class="col-lg-10">
              <table class="table customer-details">
                <tr>
                  <td class="col-6">
                    <strong>{{ $t("CUSTOMERS.LAST_NAME") }}</strong>
                  </td>
                  <td>
                    {{ this.customer.firstname }} {{ this.customer.lastname }}
                  </td>
                </tr>
                <tr>
                  <td class="col-6">
                    <strong>{{ $t("CUSTOMERS.EMAIL_SHORT") }}</strong>
                  </td>
                  <td>
                    {{ this.customer.email }}
                  </td>
                </tr>
                <tr>
                  <td class="col-6">
                    <strong>{{ $t("CUSTOMERS.PHONE_SHORT") }}</strong>
                  </td>
                  <td>
                    {{ this.customer.phone }}
                  </td>
                </tr>
                <tr>
                  <td class="col-6">
                    <strong>{{ $t("COMMON.CITY") }}</strong>
                  </td>
                  <td>
                    {{ this.customer.city }}
                  </td>
                </tr>
                <tr>
                  <td class="col-6">
                    <strong>{{ $t("COMMON.COUNTRY") }}</strong>
                  </td>
                  <td>
                    {{ this.customer.country }}
                  </td>
                </tr>
              </table>
            </div>
            <!-- region Boutons pour modifier le client -->
            <div class="col-lg-2 text-center">
              <div class="row">
                <el-button @click="customerStep = true">
                  {{ $t("COMMON.UPDATE") }}
                </el-button>
              </div>
              <div class="row" v-if="false">
                <el-button type="success">
                  {{ $t("COMMON.ADD") }}
                </el-button>
              </div>
            </div>
            <!-- endregion -->
          </div>
          <!-- formulaire de modification cu client -->
          <edit-booking-customer
              v-if="customerStep"
              @onEditBookingCustomerDone="onEditBookingCustomerDone"
              @onEditBookingCustomerCancelled="onEditBookingCustomerCancelled"
              :sales-order-organization-id="this.salesOrder.organization.id"
              :is-order-draft="this.salesOrder.status === 'DRAFT'"
              :customer-data="this.customer">
          </edit-booking-customer>
        </el-collapse-item>
        <!-- endregion -->

        <!-- region moyen de paiement -->
        <el-collapse-item name="3">
          <template slot="title">
            <h3>
              {{ $t("CUSTOMERS.PAYMENT_MODE") }}
            </h3>
          </template>
          <div class="float-left">
            <h4>
              {{ $t("CUSTOMERS.PAYMENT_MODE") }}
            </h4>
            <span>
              {{ $t("CUSTOMERS.PAYMENT_METHODS_MANAGE") }}
          </span>
          </div>
          <div v-if="this.customer">
            <div class="float-right">
              <el-button type="success">
                {{ $t("CUSTOMERS.PAYMENT_METHODS_PAYMENTS") }}
              </el-button>
            </div>
            <credit-card-selector
                v-if="this.customer"
                :customer-id="this.customer.id"
                v-model="this.selectedCard"
                @creditCardChanged="() => {}"
            />
          </div>
        </el-collapse-item>
        <!-- endregion -->
      </el-collapse>
      <div class="mt-3 float-">
        <el-button type="default" @click="onSalesOrderEditionCancelled">
          {{ $t("COMMON.GO_BACK") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, Button, CollapseItem} from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import CreditCardSelector from "@/components/CreditCardSelector.vue";
import BookingCard from "../partials/pendingBookings/BookingCard.vue";
import EditBookingForm from "../partials/EditBookingForm.vue";
import EditBookingCustomer from "../partials/form/EditBookingCustomer.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EditBookingCustomer,
    EditBookingForm,
    BookingCard,
    CreditCardSelector,
    [Collapse.name]: Collapse,
    [Button.name]: Button,
    [CollapseItem.name]: CollapseItem,
  },

  mixins: [alertLeave],

  props: {
    salesOrderId: {
      type: [String, Number],
      required: false,
    }
  },

  data()
  {
    return {
      selectedCard: null,
      salesOrder: null,
      customer: null,
      formErrors: null,
      loading: false,
      customerStep: false,
      selectedBooking: null // L'identifiant de la reservation qu'on est entrain de modifier.
    };
  },

  async created() {
    await this.get();
  },

  methods: {
    async get()
    {
      this.loading = true;
      try {
        await this._get();
      }
      catch (error)
      {
        console.log(error);

        this.$notify({type: "danger", message: `Erreur imprévue`});
      }
      finally {
        this.loading = false;
      }
    },

    async _get()
    {
      await this.$store.dispatch("salesOrders/get", this.salesOrderId);
      this.salesOrder = this.$store.getters["salesOrders/salesOrder"];

      if(this.salesOrder.recipient)
      {
        this.customerStep = false;
        await this.$store.dispatch("customers/get", this.salesOrder.recipient.id)
        this.customer = this.$store.getters["customers/customer"];
      }
      else {
        this.customerStep = true;
      }
    },

    async onEditBookingCustomerDone(updatedCustomer, isNewCustomer)
    {
      if(isNewCustomer)
      {
        this.loading = true;
        try
        {
          console.log("onEditBookingCustomerDone", updatedCustomer)
          await this.updateSalesOrderCustomer(updatedCustomer);
          await this._get();

          this.onEditBookingCustomerCancelled();
          this.$emit("customerUpdated");
        }
        catch (error)
        {
          console.log(error);

          this.$notify({type: "danger", message: `Erreur imprévue`});
        }
        finally {
          this.loading = false;
        }
      }
      else
      {
        this.customer = updatedCustomer;
        this.onEditBookingCustomerCancelled();
        this.$emit("customerUpdated");
      }
    },

    async updateSalesOrderCustomer(newCustomer)
    {
      // mise a jour de la commande avec le nouveau client
      this.salesOrder.recipient = {
        type: newCustomer.type,
        id: newCustomer.id
      };
      this.salesOrder.relationshipNames.push("recipient") ;

      // mise a jout des billing data
      this.salesOrder.billing_address = newCustomer.billing_address ?? newCustomer.billing_address;
      this.salesOrder.billing_city = newCustomer.billing_city ?? newCustomer.billing_city;
      this.salesOrder.billing_company_name = newCustomer.billing_company_name ?? newCustomer.billing_company_name;
      this.salesOrder.billing_country = newCustomer.billing_country ?? newCustomer.billing_country;
      this.salesOrder.billing_email = newCustomer.billing_email ?? newCustomer.billing_email;
      this.salesOrder.email = newCustomer.email ?? newCustomer.email;
      this.salesOrder.billing_entity_type = newCustomer.billing_entity_type ?? newCustomer.billing_entity_type;
      this.salesOrder.billing_firstname = newCustomer.billing_firstname ?? newCustomer.billing_firstname;
      this.salesOrder.billing_lastname = newCustomer.billing_lastname ?? newCustomer.billing_lastname;
      this.salesOrder.billing_state = newCustomer.billing_state ?? newCustomer.billing_state;
      this.salesOrder.billing_zipcode = newCustomer.billing_zipcode ?? newCustomer.billing_zipcode;

      await this.$store.dispatch("salesOrders/update", cloneDeep(this.salesOrder)) ;

      // mise a jour des bookings avec le nouveau client
      for (let item of this.salesOrder.items)
      {
        let booking = cloneDeep(item.salesOrderable) ;
        booking.customer =  {
          type: newCustomer.type,
          id: newCustomer.id
        };
        booking.relationshipNames.push("customer") ;
        await this.$store.dispatch("bookings/update", booking) ;
      }
    },

    onEditBookingCustomerCancelled() {
      this.customerStep = false;
    },

    editBooking(booking) {
      this.selectedBooking = booking ;
    },

    onBookingEditionCancelled() {
      this.selectedBooking = null ;
    },

    onSalesOrderEditionCancelled() {
      this.$emit("onSalesOrderEditionCancelled");
    },

    onBookingEditionDone(booking, status)
    {
      this.$emit("onViewBooking", booking, status);
      this.$emit("bookingUpdated", booking);
      this.onBookingEditionCancelled();
      this.get() ;
    },
  },
};
</script>
