<template>
  <div class="container">
    <span class="resize-loading" v-if="isLoading">
      <span class="loader"></span>
    </span>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <!-- date de reservation -->
          <base-input class="col-md-6" :label="$t('BOOKINGS.BOOKING_DATE')">
            <el-date-picker
                v-model="selectedDateRange"
                type="daterange"
                start-placeholder="Date de début"
                end-placeholder="Date de fin"
                format="dd MMM yyyy"
                value-format="yyyy-MM-dd"
                :min="new Date()"
                :clearable="false"
                :picker-options="{ disabledDate: isDisabledDate }"
                @change="onDateSelected"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.start_at" />
        </div>
        <!-- Tarif -->
        <div class="col-md-6">
          <base-input :label="$t('SPOTS.CHANGE_RATES')">
            <el-select v-model="booking.rate"
                       :label="$t('SPOTS.CHANGE_RATES')"
                       :placeholder="$t('SPOTS.CHANGE_RATES')"
                       @change="onFormChanged">
            </el-select>
            <validation-error :errors="apiValidationErrors.rate" />
          </base-input>
        </div>
      </div>

      <!-- region Occupants du camping -->
      <div class="row">
        <div class="col-md-4">
          <!-- adult capacity -->
          <base-input :label="$t('SPOTS.SPOT_ADULT_CAPACITY')">
            <el-select
                :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
                :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
                v-model="booking.adults_count"
                @change="onAdultCapacityChanged"
            >
              <el-option
                  v-for="n in Array.from({ length: maxAdults }, (_, i) => i + 1)"
                  :key="n"
                  :value="n"
                  :label="n"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.adults_count" />
        </div>
        <!-- children capacity -->
        <div class="col-md-4">
          <base-input :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')">
            <el-select
                :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
                :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
                v-model="booking.children_count"
                @change="onFormChanged"
            >
              <el-option
                  v-for="n in maxChildren + 1"
                  :key="n"
                  :value="n - 1"
                  :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.children_count" />
        </div>
        <!-- pets capacity -->
        <div class="col-md-4">
          <base-input :label="$t('SPOTS.SPOT_PETS_CAPACITY')">
            <el-select
                :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
                :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
                v-model="booking.pets_count"
                @change="onFormChanged"
            >
              <el-option
                  v-for="n in maxPets + 1"
                  :key="n"
                  :value="n - 1"
                  :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.pets_count" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Lits -->
      <div class="row">
        <div class="col-md-4" v-if="shouldIDisplayBedsQuantityField">
          <base-input :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`">
            <el-select
                :label="$t('SPOTS.BEDS_TOTAL_QTY')"
                :placeholder="$t('SPOTS.BEDS_TOTAL_QTY')"
                v-model="booking.beds_qty"
                @change="onFormChanged"
            >
              <el-option
                  v-for="n in spot.beds_total_qty + 1"
                  :key="n"
                  :value="n - 1"
                  :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.beds_qty" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Equipements -->
      <div class="row">
        <!-- Equipments -->
        <div class="col-md-6" v-if="isLandOrSeasonalFormType">
          <base-input :label="$t('SPOTS.EQUIPMENT_SELECTION')">
            <allowed-equipment-selector
                :value="booking.equipment"
                :options-value="spot.allowed_equipment"
                @valueChanged="(value) => (booking.equipment = value)"
                allowNone
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.equipment" />
        </div>
        <!-- Equipments length -->
        <div class="col-md-6" v-if="isLandOrSeasonalFormType && booking.equipment && booking.equipment !== TENT">
          <base-input
              type="text"
              :min="0"
              :max="maxEquipmentLength"
              :label="$t('SPOTS.EQUIPMENT_LENGTH')"
              :placeholder="$t('SPOTS.EQUIPMENT_LENGTH')"
              :name="`'${$t('SPOTS.EQUIPMENT_LENGTH')}'`"
              :rules="equipmentLengthValidationRules"
              v-model="booking.equipment_length"
              @change="onFormChanged"
          >
            <template #label>
              <label class="form-control-label" for="">
                {{ $t("SPOTS.EQUIPMENT_LENGTH") }}
                <span v-if="maxEquipmentLength" class="h5 text-muted font-italic">
                  ({{ $t("SPOTS.MAX_LENGTH", { length: maxEquipmentLength ?? 0 }) }})
            </span>
              </label>
            </template>
          </base-input>
          <validation-error :errors="apiValidationErrors.equipment_length" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Extensions -->
      <div class="row">
        <!-- Passager -->
        <div class="col-md-6" v-if="isLandOrSeasonalFormType">
          <base-input :label="$t('SPOTS.PASSENGER_EXTENSION')">
            <el-select
                :label="$t('SPOTS.PASSENGER_EXTENSION')"
                :placeholder="$t('SPOTS.PASSENGER_EXTENSION')"
                v-model="booking.passenger_extensions"
                @change="onFormChanged"
            >
              <el-option
                  v-for="n in spot.passenger_extensions + 1"
                  :key="n"
                  :value="n - 1"
                  :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.passenger_extensions" />
        </div>
        <!-- Conducteur -->
        <div class="col-md-6" v-if="isLandOrSeasonalFormType">
          <base-input :label="$t('SPOTS.DRIVER_EXTENSION')">
            <el-select
                :label="$t('SPOTS.DRIVER_EXTENSION')"
                :placeholder="$t('SPOTS.DRIVER_EXTENSION')"
                v-model="booking.driver_extensions"
                @change="onFormChanged"
            >
              <el-option
                  v-for="n in spot.driver_extensions + 1"
                  :key="n"
                  :value="n - 1"
                  :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.rate" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Services inclus et électricité -->
      <div class="row">
        <!-- Services inclus: should not be rendered -->
        <div class="col-md-6" v-if="isLandOrSeasonalFormType && false">
          <base-input :label="$t('SPOTS.SERVICES_INCLUDED')">
            <service-included-selector
                :options-value="spot.services_included"
                :value="booking.services_included"
                @valueChanged="(value) => (booking.services_included = value)"
                allow-none
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.services_included" />
        </div>
        <!-- Electricity -->
        <div class="col-md-12" v-if="isLandOrSeasonalFormType">
          <base-input :label="$t('SPOTS.ELECTRICITY')">
            <electricity-selector
                :options-value="spot.electricity"
                :value="booking.electricity"
                @valueChanged="(value) => (booking.electricity = value)"
                allow-none
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.electricity" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Notes supps -->
      <div class="row">
        <!-- Demande client -->
        <div class="col-md-6">
          <base-input
              :label="$t('BOOKINGS.SPECIAL_REQUEST')"
              :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')">
            <el-input type="textarea" v-model="booking.special_request"></el-input>
          </base-input>
          <validation-error :errors="apiValidationErrors.special_request" />
        </div>
        <!-- Note interne -->
        <div class="col-md-6">
          <base-input
              :label="$t('BOOKINGS.INTERNAL_NOTE')"
              :placeholder="$t('BOOKINGS.INTERNAL_NOTE')">
            <el-input type="textarea" v-model="booking.comment"></el-input>
          </base-input>
          <validation-error :errors="apiValidationErrors.comment" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region blocage de l'emplacement -->
      <div class="row">
        <div class="col-md-12">
          <label class="pers-count">
            {{ $t("BOOKINGS.BLOCK_THIS_SPOT") }}
          </label>
          <div class="mt-2">
            <el-radio-group v-model="booking.is_blocked">
              <el-radio :label="false">
                {{ $t("COMMON.NO") }}
              </el-radio>
              <el-radio :label="true">
                {{ $t("COMMON.YES") }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- endregion -->

      <!-- region action button -->
      <div class="row">
        <div class="mt-3 float-left">
          <el-button type="default" @click="onBookingEditionCancelled">
            {{ $t("COMMON.CANCEL") }}
          </el-button>
          <el-button type="primary" @click="handleSubmit">
            {{ $t("COMMON.UPDATE_ITEM") }}
          </el-button>
        </div>
      </div>
      <!-- endregion -->
    </div>
  </div>
</template>

<script>
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
  Radio,
  RadioGroup
} from "element-ui";import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import {
  BOOKING_FORM_TYPE_LAND, BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_READY_TO_CAMP,
  BOOKING_FORM_TYPE_SEASONAL
} from "@/constants/spotCategories";
import {SPOT_STATUS_NOT_AVAILABLE} from "@/constants/common";
import { TENT } from "@/constants/allowedEquipments";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import ServiceIncludedSelector from "@/components/ServiceIncludedSelector.vue";
import {cloneDeep} from "lodash";

export default {
  name: "EditBookingForm",

  components: {
    ServiceIncludedSelector,
    ElectricitySelector,
    AllowedEquipmentSelector,
    ValidationError,
    [DatePicker.name]: DatePicker,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      required: true
    }
  },

  data()
  {
    let selectedDateRange = [
        this.bookingData.start_at,
        this.bookingData.end_at
    ];

    const spot = { ...this.bookingData.spot };
    const booking = { ...this.bookingData } ;
    delete booking.spot ;

    const maxAdults = spot.adults_capacity;
    const maxChildren = spot.children_capacity;
    const maxPets = spot.pets_capacity ;

    const maxEquipmentLength = this.bookingData.spot.equipment_length ?? 10;
    const equipmentLengthValidationRules = `numeric|min_value:0|max_value:${maxEquipmentLength}`;

    return {
      spot,
      booking,
      maxAdults: maxAdults,
      maxChildren: maxChildren,
      maxPets: maxPets,
      selectedDateRange: selectedDateRange,
      equipmentLengthValidationRules: equipmentLengthValidationRules,
      maxEquipmentLength,
      formErrors: null,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      TENT,
      isLoading: false
    }
  },

  methods: {
    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },
    onAdultCapacityChanged(capacity) {
      this.booking.adults_count = capacity;
      this.onFormChanged();
    },
    onBookingEditionCancelled() {
      this.$emit("onBookingEditionCancelled") ;
    },
    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.onFormChanged();
    },
    /**
     * Détermine si une date doit être sélectionnable pour rechercher un emplacement pour reservation.
     *
     * @param {Date} date
     */
    isDisabledDate(date) {
      return this.setDateTimeComponentToZero(new Date()) > date;
    },
    /**
     * Mettre a 0 l'heure d'une date avec heure.
     *
     * @param {Date} date
     * @returns {Date}
     */
    setDateTimeComponentToZero(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);

      return date;
    },

    async handleSubmit()
    {
      this.isLoading = true;
      const bookingData = cloneDeep(this.booking);

      try
      {
        await this.$store.dispatch("bookings/update", bookingData);

        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_UPDATED"),
        });

        const booking = await this.$store.getters["bookings/booking"];

        this.$emit("onBookingEditionDone", booking, true);
      }
      catch (error)
      {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response.data.errors;
      }
      finally {
        this.isLoading = false;
      }
    }
  },

  watch: {
    formErrors(errors)
    {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },

  computed: {
    shouldIDisplayBedsQuantityField() {
      return (
          this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
          this.spot.category.booking_form_type !==
          BOOKING_FORM_TYPE_READY_TO_CAMP &&
          this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isSeasonalFormType() {
      return (
          this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isLandFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND;
    },
    isLandOrSeasonalFormType() {
      return this.isLandFormType || this.isSeasonalFormType;
    },
    isSpotUnavailable() {
      return this.spot.status === SPOT_STATUS_NOT_AVAILABLE;
    },
  }
}
</script>

<style scoped>

</style>