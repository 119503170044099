<!--
Formulaire de modification d'un client pour une reservation.
TODO : Voir comment reprendre des parties de BookingCustomerSelection
-->
<template>
  <div class="container">
    <span class="resize-loading" v-if="isLoading">
      <span class="loader"></span>
    </span>
    <!-- region Type de selection pour le client -->
    <div class="form-wrapper">
      <base-input v-if="isOrderDraft">
        <el-radio-group v-model="customerSelectionMethod" @change="onCustomerSelectionMethodChange">
          <el-radio :label="CUSTOMER_CHOICE_SELECT">
            {{ $t("BOOKINGS.EXISTING_CUSTOMER") }}
          </el-radio>
          <el-radio :label="CUSTOMER_CHOICE_UPDATE">
            {{ this.customerData ? $t("COMMON.UPDATE_ITEM") : $t("COMMON.ADD_ITEM") }}
          </el-radio>
        </el-radio-group>
      </base-input>
    </div>
    <!-- endregion -->

    <!-- region Selection du client -->
    <div class="form-wrapper" v-if="customerSelectionMethod === CUSTOMER_CHOICE_SELECT">
      <base-input>
        <customer-selector
            :customer="customerModel?.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="customerModel.organization?.id"
            @customerChanged="customerChanged">
        </customer-selector>
      </base-input>
      <validation-error :errors="apiValidationErrors.customer" />
    </div>
    <!-- endregion -->

    <!-- region Formulaire d'ajout ou de modification -->
    <div v-if="customerSelectionMethod === CUSTOMER_CHOICE_UPDATE">
      <div class="form-wrapper width_1-2">
        <base-input
            :label="`${$t('CUSTOMERS.LAST_NAME')} (*)`"
            :placeholder="`${$t('CUSTOMERS.LAST_NAME')}`"
            v-model="customerModel.lastname"
        ></base-input>
        <validation-error :errors="apiValidationErrors.lastname" />
      </div>

      <div class="form-wrapper width_1-2">
        <base-input
            :label="`${$t('CUSTOMERS.FIRST_NAME')} (*)`"
            :placeholder="`${$t('CUSTOMERS.FIRST_NAME')}`"
            v-model="customerModel.firstname"
        ></base-input>
        <validation-error :errors="apiValidationErrors.firstname" />
      </div>

      <div class="form-wrapper width_1">
        <base-input
            :label="`${$t('CUSTOMERS.EMAIL')} (*)`"
            :placeholder="`${$t('CUSTOMERS.EMAIL')}`"
            v-model="customerModel.email"
            type="email"
        ></base-input>
        <validation-error :errors="apiValidationErrors.email" />
      </div>

      <div class="form-wrapper width_1">
        <base-input
            :label="`${$t('COMMON.BILLING_ADDRESS')} (*)`"
            :placeholder="`${$t('COMMON.BILLING_ADDRESS')}`"
            v-model="customerModel.billing_address">
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_address" />
      </div>

      <div class="form-wrapper width_1 equal-item phone">
        <base-input
            :label="`${$t('CUSTOMERS.PHONE')}`"
            :placeholder="`${$t('CUSTOMERS.PHONE')}`"
        >
          <phone-number-input
              :phoneNumber="customerModel.phone"
              @phoneNumberChanged="
            (phone) => {
              customerModel.phone = phone;
            }
          "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>

      <div class="form-wrapper width_1">
        <base-input
            :label="$t('CUSTOMERS.COUNTRY')"
            :placeholder="$t('CUSTOMERS.COUNTRY')"
        >
          <country-selector
              :country="customerModel.country"
              :filterable="true"
              :showAll="false"
              @countryChanged="
            (country) => {
              customerModel.country = country;
            }
          "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>

      <div class="form-wrapper width_1">
        <base-input
            :label="$t('CUSTOMERS.ADDRESS')"
            :placeholder="$t('COMMON.ADDRESS')"
            v-model="customerModel.address"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.address" />
      </div>

      <div class="form-wrapper width_1">
        <base-input :placeholder="$t('COMMON.CITY')" v-model="customerModel.city">
        </base-input>
        <validation-error :errors="apiValidationErrors.city" />
      </div>

      <div class="form-wrapper width_1">
        <base-input :placeholder="$t('COMMON.CITY')" v-model="customerModel.city">
          <state-selector
              :country="customerModel.country"
              :state="customerModel.state"
              :filterable="true"
              :showAll="false"
              @stateChanged="
            (state) => {
              customerModel.state = state;
            }
          "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.city" />
      </div>

      <div class="form-wrapper width_1">
        <base-input
            :placeholder="$t('COMMON.ZIPCODE')"
            v-model="customerModel.zipcode"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>
    </div>

    <!-- endregion -->

    <!-- region Boutons d'actions -->
    <div class="row">
      <div class="mt-3 float-left">
        <el-button :disabled="!this.customerData"
                   type="default" @click="onEditBookingCustomerCancelled">
          {{ $t("COMMON.CANCEL") }}
        </el-button>
        <el-button :disabled="!this.selectedCustomer && customerSelectionMethod === CUSTOMER_CHOICE_SELECT"
                   type="primary"
                   @click="onCustomerUpdateClick">
          {{ this.customerData ? $t("COMMON.UPDATE_ITEM") : $t("COMMON.ADD_ITEM") }}
        </el-button>
      </div>
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import StateSelector from "@/components/StateSelector.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import {cloneDeep} from "lodash";
import defaultCustomer from "@/views/Pages/CrmModule/CustomerManagement/defaultCustomer";
import formMixin from "@/mixins/form-mixin";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Option,
  Radio,
  RadioGroup,
  Row,
  Select,
  Step,
  Steps,
} from "element-ui";
import CustomerSelector from "@/components/CustomerSelector.vue";
import {CUSTOMER_FORM_TYPE_BOOKING, CUSTOMER_TYPE_COMPANY, CUSTOMER_TYPE_INDIVIDUAL} from "@/constants/customers";
import defaultOrganization from "@/views/Pages/AdminModule/OrganizationManagement/defaultOrganization";

const CUSTOMER_CHOICE_SELECT = "SELECT";
const CUSTOMER_CHOICE_UPDATE = "UPDATE";

export default {
  name: "EditBookingCustomer",

  components: {
    CustomerSelector,
    ValidationError,
    CountrySelector,
    StateSelector,
    PhoneNumberInput,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    customerData: {
      type: Object,
      required: false
    },
    salesOrderOrganizationId: {
      type: [String, Number],
      required: false
    },
    isOrderDraft: {
      type: Boolean,
      default: true
    }
  },

  data() {
    let customerModel = this.customerData ? cloneDeep(this.customerData) : cloneDeep(defaultCustomer);
    customerModel.form_type = CUSTOMER_FORM_TYPE_BOOKING;

    return {
      customerSelectionMethod: this.customerData ? CUSTOMER_CHOICE_UPDATE : CUSTOMER_CHOICE_SELECT,
      customerModel,
      selectedCustomer: null,
      loading: false,
      formErrors: null,
      isLoading: false,
      CUSTOMER_CHOICE_SELECT,
      CUSTOMER_CHOICE_UPDATE
    };
  },

  methods: {
    async onCustomerUpdateClick()
    {
      let updatedCustomer;
      this.isLoading = true;
      let isNewCustomer = !this.customerData ;

      // Choix select
      try
      {
        // Choix update
        if(this.customerSelectionMethod === CUSTOMER_CHOICE_UPDATE)
        {
          // Vraiment update
          if(this.customerData) {
            updatedCustomer = await this.updateCurrentCustomer() ;
          }
          // Plutôt création du client
          else {
            updatedCustomer = await this.createNewCustomer() ;
          }

          this.$emit("onEditBookingCustomerDone", updatedCustomer, isNewCustomer);
        }
        // Choix select
        else if(this.customerSelectionMethod === CUSTOMER_CHOICE_SELECT && this.selectedCustomer)
        {
            isNewCustomer = true ;
            updatedCustomer = this.selectedCustomer ;
            this.$emit("onEditBookingCustomerDone", updatedCustomer, isNewCustomer);
        }
      }
      catch (error)
      {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        console.log(error) ;
        this.formErrors = error.response.data.errors;
      }
      finally {
        this.isLoading = false;
      }
    },

    async createNewCustomer()
    {
      let customer = cloneDeep(this.customerModel);
      customer.organization = {
        id: this.salesOrderOrganizationId,
        type: defaultOrganization.type
      };

      if (customer.billing_entity_type === CUSTOMER_TYPE_INDIVIDUAL)
      {
        customer.company_name = "N/A";
        customer.billing_company_name = "N/A";
        customer.billing_firstname = "N/A";
        customer.billing_lastname = "N/A";
      }
      else if (customer.billing_entity_type === CUSTOMER_TYPE_COMPANY)
      {
        customer.firstname = "N/A";
        customer.lastname = "N/A";
        customer.billing_firstname = "N/A";
        customer.billing_lastname = "N/A";
      }
      customer.billing_country = (customer.country?.length > 0) ? customer.country : "N/A";
      customer.billing_state = (customer.state?.length > 0) ? customer.state : "N/A";
      customer.billing_city = (customer.city?.length > 0) ? customer.city : "N/A";
      customer.billing_zipcode = (customer.zipcode?.length > 0) ? customer.zipcode : "N/A";
      customer.billing_email = customer.email;

      await this.$store.dispatch("customers/add", customer);

      let newCustomerId = this.$store.getters["customers/customer"].id ;
      await this.$store.dispatch("customers/get", newCustomerId)

      return await this.$store.getters["customers/customer"];
    },

    async updateCurrentCustomer()
    {
      const customerData = cloneDeep(this.customerModel);

      await this.$store.dispatch("customers/update", customerData);
      this.$notify({
        type: "success",
        message: this.$t("CUSTOMERS.CUSTOMER_UPDATED"),
      });

      await this.$store.dispatch("customers/get", customerData.id)

      return await this.$store.getters["customers/customer"];
    },

    customerChanged(customerId, customer) {
      this.selectedCustomer = cloneDeep(customer) ;
    },

    onEditBookingCustomerCancelled()
    {
      if(this.customerData) {
        this.$emit("onEditBookingCustomerCancelled");
      }
    },

    onCustomerSelectionMethodChange() {
      this.selectedCustomer = null ;
    }
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  }
}
</script>

<style scoped>

</style>